import React,{Component} from 'react';

import { Link } from 'react-router-dom';

// import OurPartners from './../Element/OurPartners';
// import PriceTable from './../Element/PriceTable';


import Header from './../Layout/Header';
import Footer from './../Layout/Footer';


var img1 = require('./../../images/banner/bnr1.jpg');
var img2 = require('./../../images/background/bg5.jpg');
var img3 = require('./../../images/background/bg4.jpg');
// var img4 = require('./../../images/background/bg1.jpg');


class Ourservices extends Component{
	render(){
		return(
			<>
			<Header /> 
			<div className="page-content bg-white">
				
				<div className="dlab-bnr-inr overlay-black-middle" style={{backgroundImage:"url(" + img1 + ")", backgroundSize:"cover"}}>
					<div className="container">
						<div className="dlab-bnr-inr-entry">
							<h1 className="text-white">Nuestros servicios</h1>
							
							{/* <div class="breadcrumb-row">
								<ul class="list-inline">
									<li><Link to={"./"}><i class="fa fa-home"></i></Link></li>
									<li>Our Services</li>
								</ul>
							</div> */}
							
						</div>
					</div>
				</div>
				
				<div className="content-block">
					
					<div className="section-full bg-white content-inner" style={{backgroundImage:"url(" + img2 + ")", backgroundSize:"100%"}}>
						<div className="container">
							<div className="row">
								<div className="col-lg-12">
									<div className="section-head text-center">
										<div className="icon-bx icon-bx-xl">
											<img src={require('./../../images/cake1.jpg')} alt="" />
										</div>
										<h3>Todo lo que necesitas</h3>
										<p>Lo puedes encontrar aquí</p>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-3 col-md-6 col-sm-6 m-b30">
									<div className="icon-bx-wraper bx-style-1 bg-white p-a30 center fly-box-ho">
										<div className="icon-bx-md bg-primary radius m-b20">
											<span className="icon-cell"><img src={require('./../../images/icons/service-icon/icon1.png')} alt="" /></span>
										</div>
										<div className="icon-content">
											<h4 className="dlab-tilte m-b5">Pasteles personalizados</h4>
											<p>Puedes ordenar con 1 semana de antelación un pastel decorado con la temática que selecciones.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-6 col-sm-6 m-b30">
									<div className="icon-bx-wraper bx-style-1 bg-white p-a30 center fly-box-ho">
										<div className="icon-bx-md bg-primary radius m-b20">
											<span className="icon-cell"><img src={require('./../../images/icons/icon5.png')} alt="" /></span>
										</div>
										<div className="icon-content">
											<h4 className="dlab-tilte m-b5">Recoger en Tienda</h4>
											<p>Puedes ordenar en línea a través de nuestros contactos y recoger el pedido en la tienda.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-6 col-sm-6 m-b30">
									<div className="icon-bx-wraper bx-style-1 bg-white p-a30 center fly-box-ho">
										<div className="icon-bx-md bg-primary radius m-b20">
											<span className="icon-cell"><img src={require('./../../images/icons/icon6.png')} alt="" /></span>
										</div>
										<div className="icon-content">
											<h4 className="dlab-tilte m-b5">Compra en tienda</h4>
											<p>Tenemos disponibilidad de cupcakes y galletas para comprar en el momento, si así lo desea.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-6 col-sm-6 m-b30">
									<div className="icon-bx-wraper bx-style-1 bg-white p-a30 center fly-box-ho">
										<div className="icon-bx-md bg-primary radius m-b20">
											<span className="icon-cell"><img src={require('./../../images/icons/icon7.png')} alt="" /></span>
										</div>
										<div className="icon-content">
											<h4 className="dlab-tilte m-b5">Mesa de postres</h4>
											<p>Se preparan mesas de postres para eventos de cumpleaños, bodas, etc. Más de 50 personas.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					
					<div className="section-full content-inner service-area2 bg-img-fix bg-line-top bg-line-bottom" style={{backgroundImage: "url(" + img3 + ")", backgroundSize:'cover' }}>
						<div className="container">
							<div className="row">
								<div className="col-lg-12">
									<div className="section-head text-center">
										<h2 className="text-white">Que ofrecemos en tienda</h2>
										<p className="text-bold">En el día a día ofrecemos nuestros productos acabados de hornear para tu disfrute.</p>
										<div className="dlab-separator style1 bg-primary"></div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-4 m-b30">
									<img src={require('./../../images/about/pic4.jpg')} className="img-cover radius-sm" alt="" />
								</div>
								<div className="col-lg-8">
									<div className="row p-l30">
										<div className="col-lg-6 col-sm-6 m-b30">
											<div className="icon-bx-wraper text-white service-box2">
												<div className="icon-bx">
													<Link to={''} className="icon-cell"><img src={require('./../../images/icons/service-icon/icon2.png')} alt="" /></Link>
												</div>
												<div className="icon-content">
													<h5 className="dlab-tilte">Galletas</h5>
													<p>De diferentes sabores</p>
												</div>
											</div>
										</div>
										<div className="col-lg-6 col-sm-6 m-b30">
											<div className="icon-bx-wraper text-white service-box2">
												<div className="icon-bx">
													<Link to={''} className="icon-cell"><img src={require('./../../images/icons/service-icon/icon3.png')} alt="" /></Link> 
												</div>
												<div className="icon-content">
													<h5 className="dlab-tilte">Cupcakes</h5>
													<p>Diferentes sabores y diseños.</p>
												</div>
											</div>
										</div>
										<div className="col-lg-6 col-sm-6 m-b30">
											<div className="icon-bx-wraper text-white service-box2">
												<div className="icon-bx">
													<Link to={''} className="icon-cell"><img src={require('./../../images/icons/service-icon/icon5.png')} alt="" /></Link> 
												</div>
												<div className="icon-content">
													<h5 className="dlab-tilte">Café</h5>
													<p>Recién hecho, calentito</p>
												</div>
											</div>
										</div>
										<div className="col-lg-6 col-sm-6 m-b30">
											<div className="icon-bx-wraper text-white service-box2">
												<div className="icon-bx"> 
													<Link to={''} className="icon-cell"><img src={require('./../../images/icons/service-icon/icon1.png')} alt="" /></Link> 
												</div>
												<div className="icon-content">
												<h5 className="dlab-tilte">Pastel de cumpleaños</h5>
													<p>Te cotizamos y lo tenemos listo en la fecha indicada</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
			
		</>
		)
	}
	
}
export default Ourservices;